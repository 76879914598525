import {h} from 'preact';
import register from 'preact-custom-element';
import { type ImageCarouselProps, ImageCarousel } from '@/components/ImageCarousel';

const renderImageCarousel = ({ items, imageClass, parentDivClasses, rightArrowIcon, leftArrowIcon }: ImageCarouselProps) => {
    return h(ImageCarousel, { items, imageClass, parentDivClasses, rightArrowIcon, leftArrowIcon })
}


register(
    renderImageCarousel,
    'image-carousel',
    ['items', 'image-class', 'parent-div-classes', 'right-arrow-icon', 'left-arrow-icon']
);
