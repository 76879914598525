import { useMemo } from 'preact/hooks';

import Slider, { type Settings } from '@ant-design/react-slick';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import { CustomCarouselArrow } from '@/components/CustomCarouselArrow';
import {logError} from '@/utilities/log';

type PhotoType = string;

export type ImageCarouselProps = {
  items: string;
  imageClass: string;
  parentDivClasses?: string;
  rightArrowIcon?: string,
  leftArrowIcon?: string
};

const baseSettings: Settings = {
  className: "slider variable-width overflow-x-hidden",
  infinite: true,
  autoplay: true,
  variableWidth: true,
  speed: 3000,
  autoplaySpeed: 3000,
  rows: 1,
  cssEase: "linear",
  // swipeToSlide: true,
};

export const ImageCarousel = ({
  items,
  imageClass,
  parentDivClasses,
  rightArrowIcon,
  leftArrowIcon
}: ImageCarouselProps) => {
  const parsedItems: PhotoType[] = useMemo(() => {
    try {
      const data = JSON.parse(items) as PhotoType[];
      return data;
    } catch (error) {
      logError(error, {itemsJson: items});
      return [];
    }
  }, [items]);

  if(rightArrowIcon && leftArrowIcon) {
    baseSettings.nextArrow = <CustomCarouselArrow link={rightArrowIcon} classNames="right-2 opacity-80 hover:opacity-100" />
    baseSettings.prevArrow = <CustomCarouselArrow link={leftArrowIcon} classNames="left-2 opacity-80 hover:opacity-100" />
    baseSettings.arrows = true;
  }

  return (
      <Slider {...baseSettings} >
        {parsedItems.map((link, index) => (
          <div
            key={`list-photo-item-${index}`}
            className={parentDivClasses}
          >
            <img
              loading="lazy"
              className={imageClass}
              src={link}
              alt="quote icon"
            />
          </div>
        ))}
      </Slider>
  );
};